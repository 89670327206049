import { render, staticRenderFns } from "./popAdd.vue?vue&type=template&id=18603d46&scoped=true&"
import script from "./popAdd.vue?vue&type=script&lang=js&"
export * from "./popAdd.vue?vue&type=script&lang=js&"
import style0 from "./popAdd.vue?vue&type=style&index=0&id=18603d46&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18603d46",
  null
  
)

export default component.exports