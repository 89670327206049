<template>
    <div class="dashboard-container">
      <!-- <search-card /> -->
      <table-card style="margin-top:30px" />
    </div>
</template>

<script>

import { 
// searchCard,
tableCard
} from '@/components/dashboard'
export default {
  name: "dashboard",
  components: {
    // searchCard,
    tableCard
  },
  data() {
    return {

    };
  },
  computed: {
  },
  mounted(){

  },
  methods: {

  }
};
</script>

<style lang="less" scoped>
.dashboard-container {
  width: 100%;
}
</style>
