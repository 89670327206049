<template>
  <div class="dashboard-pop-edit-container">
    <a-modal
      :title="row.id?'编辑成员':'新增成员'"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      width="800px"
    >

<a-form
        :form="form"
        :label-col="{ span: 10 }"
        :wrapper-col="{ span: 14 }"
        @submit="handleSubmit"
      >

      <a-row v-if="row.id">
        <a-col :span="12">
          <a-form-item label="用户昵称">
            {{row.uname}}
        </a-form-item>

        </a-col>
        <a-col :span="12">
         <a-form-item label="用户UID">
          {{row.uid}}
        </a-form-item>
      </a-col>
      </a-row>

      <a-row v-else>
        <a-col :span="12">
          <a-form-item label="用户昵称">
          <a-input
            v-decorator="formDecorators.uname"
          />
        </a-form-item>

        </a-col>
        <a-col :span="12">
         <a-form-item label="用户UID">
          <a-input
            v-decorator="formDecorators.uid"
          />
        </a-form-item>
      </a-col>
      </a-row>

      <a-row>
        <a-col :span="12">
          <a-form-item label="上级">
          <a-tree-select
            v-decorator="formDecorators.pid"
            style="width: 100%"
            :tree-data="menus"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            placeholder="请选择用户所属项目组"
          >
          </a-tree-select>
        </a-form-item>
        </a-col>

        <a-col :span="12">
        <a-form-item label="用户角色">
          <a-select  v-decorator="formDecorators.role_id" >
              <a-select-option :value="item.id" v-for="item in list" :key="item.id">
                {{item.title}}
              </a-select-option>
            </a-select>
        </a-form-item> 
      </a-col>
      </a-row>

      <a-row>
        <a-col :span="12">
         <a-form-item label="用户账号">
          <a-input-number style="width:220px"
            v-decorator="formDecorators.account"
          />
        </a-form-item>
        </a-col>
        <a-col :span="12">
        <a-form-item label="登录密码">
          <a-input-password 
          v-decorator="formDecorators.password"
          placeholder="登录密码" />
        </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="12">
        <a-form-item label="所属公会">
          <a-select
            v-decorator="formDecorators.guild_manage_id"
          >
            <a-select-option :value="item.id"  v-for="item in guildList"  :key="item.id" >{{item.name}}</a-select-option>
          </a-select>
        </a-form-item>
        </a-col>
        <a-col :span="12">
        <a-form-item label="排序">
          <a-input
            min="0"
            type="number"
            v-decorator="formDecorators.sort"
            placeholder="数字越小越靠前"
          />
        </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
        <a-form-item label="小程序权限">
          <a-select
            v-decorator="formDecorators.mini_program_permission"
          >
              <a-select-option :value="1">是</a-select-option>
              <a-select-option :value="2">否</a-select-option>
          </a-select>
        </a-form-item>
        </a-col>
      </a-row>

      </a-form>
    </a-modal>
  </div>
</template>

<script>
import tool from "@/utils/tool";
export default {
  data() {
    return {
      confirmLoading: false,
      form: this.$form.createForm(this, { name: "coordinated" }),
      menuList:[],
      guildList:[],
      list:[]
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    row: {
      type: Object,
      default: ()=>{
        return {}
      },
    },
    menus: {
      type: Array,
      default:()=>{
        return []
      }
    },
  },
  computed: {
    formDecorators() {
      let data = JSON.parse(JSON.stringify(this.row));

      return {
        pid: [
          "pid",
          {
            initialValue: data && data.pid,
            rules: [{ required: true, message: "请选择项目组" }],
            // validateTrigger: ["blur"],
          },
        ],
        uname: [
          "uname",
          {
            initialValue: data && data.uname
          },
        ],
        uid: [
          "uid",
          {
            initialValue: data && data.uid,
            rules: [{ required: true, message: "请填写用户uid" }],
            validateTrigger: ["blur"],
          },
        ],
        role_id: [
          "role_id",
          {
            initialValue: data && data.role_id,
            rules: [{ required: true, message: "请选择用户角色" }],
          },
        ],
        account: [
          "account",
          {
            initialValue: data && data.account,
            rules: [{ required: true, message: "请填写用户登录账号" }],
            validateTrigger: ["blur"],
          },
        ],
        password: [
          "password",
          {
            initialValue: (data && data.password)||'',
          },
        ],
        sort: [
          "sort",
          {
            initialValue: (data && data.sort)||0,
          },
        ],
        guild_manage_id: [
          "guild_manage_id",
          {
            initialValue: data && data.guild_manage_id,
          },
        ],
          mini_program_permission: [
              "mini_program_permission",
              {
                  initialValue: data && data.mini_program_permission,
              },
          ]
      };
    },
  },
  created(){
    this.getRoles()
    this.getGuild()
  },
  mounted() {
    console.log('rowrowrowrowrowrowrow' , this.row);
  },
  filters:{

  },
  methods: {

    //获取所有公会接口
    getGuild(){
      this.$api.get(`v2/guildManage/all`).then((res) => {
        if(res.code==200){
          this.guildList=res.data
        }else{
          this.$message.error(res.message)
        }
      });
    },

    //获取角色列表
    getRoles(){
      this.$api.get(`v2/adminRole/sel?perPage=30`).then((res) => {
        console.log(res);
        if(res.code==200){
          this.list=res.data.list
        }else{
          this.$message.error(res.message)
        }
      });
    },

    // 图标选择
    handleIconChange (icon) {
      this.icon = icon
      let fieldsValue = {
        icons: icon
      }
      this.form.setFieldsValue(fieldsValue)
    },

    handleOk(){
      this.confirmLoading = true;
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(values,values.guild_manage_id);
          for(let i =0;i<this.list.length;i++){
            if(values.role_id==this.list[i].id){
              values.role_name=this.list[i].title
            }
          }
          values.guild_manage_id=values.guild_manage_id
          if(this.row.id){
            values.uid=this.row.uid
          }

          // values.account=values.account+''

          this.$api.post(`/v2/admin/add`, values)
            .then((resp) => {
              if (resp.code == 200) {
                this.$message.success("操作成功");
                this.$emit("handleOk");
                tool.$emit("changeMenu"); // 切换菜单
              } else {
                this.$message.error(resp.message);
              }
              this.confirmLoading = false;
            })
            .catch((e) => {
              this.confirmLoading = false;
              throw e;
            });
        }else{
          this.confirmLoading = false;
        }
        })
    },
    handleCancel(){
      this.$emit('handleCancel')
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.dashboard-pop-edit-container {
}

.list {
  display: flex;
  margin-bottom: 30px;
  .list-left {
    display: flex;
    width: 350px;
    align-items: center;
  }
  .list-right {
    display: flex;
    align-items: center;
    margin-left: 50px;
  }
}

.label {
  width: 100px;
  font-size: 20px;
  color: #333333;
  display: flex;
  justify-content: space-between;
}
.value {
  font-size: 20px;
  color: #199aec;
  display: flex;
}
.l-height{
  line-height: 30px;
}
@media screen and(max-width:750px) {
  
.list {
  display: block;
  margin-bottom: 0px;
  .list-left {
    display: flex;
    width: 350px;
    align-items: center;
    margin-bottom: 15px;
  }
  .list-right {
    display: flex;
    align-items: center;
    margin-left: 0px;
    margin-bottom: 15px;
  }
}

.label {
  width: 100px;
  font-size: 16px;
  color: #333333;
  display: flex;
  justify-content: space-between;
}
.value {
  font-size: 16px;
  color: #199aec;
  display: flex;
}
.l-height{
  line-height: 30px;
}
}

</style>
