<template>
    <div class="dashboard-table-card-container ">
        <le-card width="100%" style="padding-bottom:20px">
            <template>
                <div class="container">
                    <div style="display: flex;align-items: center;justify-content: space-between;height: 60px; ">
                        <div class="card-title" style="padding-top: 0;">团队管理
                            <!-- <div class="num-box">{{pageOptions.total}} 0人</div> -->
                        </div>
                        <div class="btn-box">
                            <le-button :loading="isLoad2" txt="去除经济人" class="le-button"
                                       @btnClick="isLoad2=true;delEconomicMan()"
                                       style="margin-right:20px" v-if="del_economic_man"/>
                            <le-button :loading="isLoad" txt="拉取最新" class="le-button" @btnClick="isLoad=true;rePull()"
                                       style="margin-right:20px" v-if="isPull"/>
                            <le-button txt="新增成员" class="le-button" @btnClick="isAdd=true;editRow={}" v-if="isAddBtn"/>
                        </div>
                    </div>

                    <div class="table-box">
                        <a-table
                                :columns="columns"
                                :data-source="dataSource"
                                :loading="loading"
                                :defaultExpandAllRows="true"
                                @change="handleTableChange"
                                v-if="dataSource.length > 0"
                                size="middle">

                            <div slot="uname" slot-scope="row">
                                <a class="name-box" :href="'https://space.bilibili.com/'+row.uid" target="_blank"
                                   rel="noopener noreferrer">
                                    <div class="avatar">
                                        <img :src="row.face_url" alt="" srcset="">
                                    </div>
                                    <div class="name-uid">
                                        <div>{{row.uname}}</div>
                                        <div>{{row.uid}}</div>
                                    </div>
                                </a>
                            </div>

                            <span slot="customTitle">开播率
                   <a-tooltip>
                  <template slot="title">
                    近3天直播过 记为有效
                  </template>
                    <a-icon type="question-circle"/>
                    </a-tooltip>
                  </span>
                            <div slot="identity" slot-scope="row">
                                <div>{{row==1?'经纪人':'工会' }}</div>
                            </div>

                            <div slot="login" slot-scope="row" class="login" @click="showLog(row.uid)">
                                <div>{{row.last_login_time | kong}}</div>
                                <div>{{row.login_info}}</div>
                            </div>
                            <span slot="action" style="display: flex;justify-content:space-between" slot-scope="row">
                    <le-button v-if="isEdit" txt="编辑" width="40px" height="30px" fontSize="14px"
                               @btnClick="clickEdit(row)"/>
                                <!--                     <le-button txt="删除" width="40px" height="30px" fontSize="14px" @btnClick="clickGoto(row)"/>-->
                  </span>
                        </a-table>
                    </div>
                </div>
            </template>
        </le-card>
        <popAdd v-if="isAdd" :visible="isAdd" @handleOk="addOk" @handleCancel="isAdd=false" :row="editRow"
                :menus="dataSource"/>
        <popEdit v-if="visible" :visible="visible" @handleOk="handleOk" @handleCancel="handleCancel" :row="editRow"/>
        <popLoginInfo v-if="visibleLog" :visible="visibleLog" @handleOk="handleOk" @handleCancel="visibleLog=false"
                      :uid="uid"/>
    </div>
</template>

<script>

    const columns = [
        {
            title: '身份',
            key: 'role_name',
            dataIndex: 'role_name'
        },
        {
            title: '公会',
            key: 'guild_manage_name',
            dataIndex: 'guild_manage_name'
        },
        {
            title: '昵称/UID',
            key: 'uname',
            scopedSlots: {customRender: "uname"},
        },
        // {
        //   title: '本月新增',
        //   dataIndex: 'new_anchor',
        //   sorter: (a, b) => a.new_anchor - b.new_anchor,
        // },
        // {
        //   title: '本月流水',
        //   dataIndex: 'income',
        //   sorter: (a, b) => a.income - b.income,
        // },

        // {
        //   title: '主播数',
        //   dataIndex: 'total_anchor',
        //   sorter: (a, b) => a.total_anchor - b.total_anchor,
        // },

        // {
        //   title: '开播数',
        //   dataIndex: 'validlive',
        //   sorter: (a, b) => a.validlive - b.validlive,
        // },
        // {
        //   dataIndex: 'validlive_rate',
        //   slots: { title: 'customTitle' },
        // },
        {
            title: '最近登录',
            key: 'login',
            scopedSlots: {customRender: "login"},
        },
        {
            title: "操作",
            width: 110,
            key: 'action',
            scopedSlots: {customRender: "action"},
        },
    ];

    import popAdd from './popAdd'
    import popEdit from './popEdit'
    import popLoginInfo from './popLoginInfo'
    import tool from '@/utils/tool'
    import {MixinList} from './mixin';

    export default {
        components: {
            popAdd,
            popEdit,
            popLoginInfo
        },
        data() {
            return {
                columns,
                dataUrl: 'v2/admin/sel',
                delUrl: 'admin/layout/del',
                isAdd: false,
                visible: false,
                visibleLog: false,
                uid: '',
                editRow: {},
                isAddBtn: false,
                isPull: false,
                isEdit: false,
                isLoad: false,
                isLoad2: false,
                del_economic_man: false
            };
        },
        computed: {},
        mixins: [MixinList],
        mounted() {
            // if(this.$route.query.temp_uid){
            //   this.dataUrl='v1/user/list?temp_uid='
            // }
            // console.log(this.$route.query.temp_uid)

            let btn = window.localStorage.getItem('whiteBtns')
            if (btn) {
                let btns = btn.split(',');
                if (btns.includes('index-dashboard-add')) {
                    this.isAddBtn = true;
                }
                if (btns.includes('index-dashboard-edit')) {
                    this.isEdit = true;
                }

                if (btns.includes('index-dashboard-pull')) {
                    this.isPull = true;
                }
                if (btns.includes('index-dashboard-delEconomicMan')) {
                    this.del_economic_man = true;
                }
            }
        },
        methods: {
            clickEdit(row) {
                this.editRow = row
                this.editRow.password = row.pw
                this.isAdd = true
                // this.visible = true;
            },
            addOk() {
                this.isAdd = false;
                this.getData()
            },
            handleOk() {
                this.visible = false;
                this.getData()
            },
            handleCancel() {
                this.visible = false;
                this.getData()
            },
            clickGoto(row) {
                let params = {id: row.id};
                this.$api.post(`v1/user/del`, params).then((res) => {
                    if (res.code === 200) {
                        this.getData()
                    } else {
                        this.$message.error(res.message)
                    }
                });
                // let routeData = this.$router.resolve({ path: '/index/dashboard', query: {  temp_uid: row.uid } });
                //  window.open(routeData.href, '_blank');
                // window.open('https://www.bilibililink.com/index/dashboard?temp_uid='+row.uid)
                // window.open('http://localhost:8080/index/dashboard?temp_uid='+row.uid)
            },
            showLog(uid) {
                console.log('uid', uid)
                this.uid = uid
                this.visibleLog = true
            },
            delEconomicMan() {
                this.$api.get(`v1/user/delEconomicMan`).then((res) => {
                    if (res.code === 200) {
                        this.isLoad2 = false
                        this.getData()
                    } else {
                        this.isLoad2 = false
                        this.$message.error(res.message)
                    }
                });
            },
            rePull() {
                this.$api.get(`v1/user/sync`).then((res) => {
                    if (res.code == 200) {
                        this.isLoad = false
                        console.log(this.isLoad)
                        this.dataSource = res.data.list.map((d) => {
                            d.key = d.created_at
                            return d
                        })

                    } else {
                        this.isLoad = false
                        this.$message.error(res.message)
                    }
                });
            }
        }
    };
</script>

<style lang="less" scoped>
    .dashboard-table-card-container {
        .container {
            position: relative;

            .title {
                padding-top: 20px;
                margin-left: 30px;
                font-size: 30px;
                font-weight: bold;
                color: #333333;
                display: flex;
                align-items: flex-end;

                .num-box {
                    font-size: 18px;
                    margin-left: 11px;
                    line-height: 32px;
                }
            }

            // .le-button{
            //   position: absolute;
            //   top: 30px;
            //   right: 30px;
            // }

            .table-box {
                margin: 40px 30px;
            }

            .btn-box {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-right: 30px;
            }
        }
    }

    .login {
        cursor: pointer;

        &:hover {
            color: #008DF0;
        }
    }

    .name-box {
        display: flex;

        .avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 10px;
            background-color: #333333;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100% 100%;

            img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
            }
        }
    }

    @media screen and(max-width: 750px) {
        .dashboard-table-card-container {
            .container {
                .title {
                    padding-top: 10px;
                    margin-left: 20px;
                    font-size: 20px;

                    .num-box {
                        font-size: 12px;
                        margin-left: 5px;
                        line-height: 22px;
                    }
                }

                // .le-button{
                //   position: absolute;
                //   top: 10px;
                //   right: 20px;
                //   width: 50px!important;
                // }

                .table-box {
                    margin: 20px;
                    overflow-x: scroll;
                }
            }
        }
    }

</style>
