<template>
  <div class="dashboard-pop-edit-container">
    <a-modal
      title="编辑用户信息"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      width="800px"
    >

      <div class="list">
        <div class="list-left">
          <div class="label">
            <div class="txt">身份</div>
            <div class="hao">：</div>
          </div>
          <div class="value">


            <a-select v-if="isFixed5" :default-value="info.role_id" style="width: 180px" @change="changeIdentity">
              <a-select-option :value="item.id" v-for="item in list" :key="item.id">
                {{item.title}}
              </a-select-option>
            </a-select>



            <!-- <a-select v-if="isFixed5" :default-value="info.identity" style="width: 180px" @change="changeIdentity">
              <a-select-option :value="item.key" v-for="item in list" :key="item.key">
                {{item.name}}
              </a-select-option>
            </a-select> -->

            <!-- <span v-else >{{info.identity==1?'经纪人':'工会'}}</span> -->
            <span v-else >{{info.role_name}}</span>
            <le-button v-if="isFixed5" txt="确认"  width="40px" height="30px" fontSize="16px" style="margin-left:19px" @btnClick="comfirm5()"  />  
            <le-button v-else txt="修改"  width="40px" height="30px" fontSize="16px" style="margin-left:19px"  @btnClick="isFixed5=true" />  
          </div>
        </div>
        <div class="list-right"></div>
      </div>

      <div class="list">
        <div class="list-left">
          <div class="label">
            <div class="txt">工会</div>
            <div class="hao">：</div>
          </div>
          <div class="value">

            <a-input v-if="isFixed1" v-model="info.group" placeholder="" style="width:180px" :maxLength="20" />
            <!-- <a-select v-if="isFixed1" :default-value="info.groups.id" style="width: 180px" @change="handleChange">
              <a-select-option :value="item.id" v-for="item in list" :key="item.id">
                {{item.group}}
              </a-select-option>
            </a-select> -->

            <span v-else >{{info.group | kong}}</span>
            <le-button v-if="isFixed1" txt="确认"  width="40px" height="30px" fontSize="16px" style="margin-left:19px" @btnClick="comfirm1()"  />  
            <le-button v-else txt="修改"  width="40px" height="30px" fontSize="16px" style="margin-left:19px"  @btnClick="isFixed1=true" />  
          </div>
        </div>
        <div class="list-right">
          <div class="label">
            <div class="txt">直属上级</div>
            <div class="hao">：</div>
          </div>
          <div class="value">
            {{info.puname}}
          </div>
        </div>
      </div>

      <div class="list">
        <div class="list-left">
          <div class="label">
            <div class="txt">UID</div>
            <div class="hao">：</div>
          </div>
          <div class="value">{{info.uid}}</div>
        </div>
        <div class="list-right">
          <div class="label">
            <div class="txt">昵称</div>
            <div class="hao">：</div>
          </div>
          <div class="value">{{info.uname}}</div>
        </div>
      </div>

      <div class="list">
        <div class="list-left">
          <div class="label">
            <div class="txt">入驻时间</div>
            <div class="hao">：</div>
          </div>
          <div class="value">
            <a-date-picker :default-value="moment(info.join_time)" v-if="isFixed2" style="width:180px"  @change="onChange" />
            <span v-else  >{{info.join_time | setJoin}}</span>

            <le-button v-if="isFixed2" txt="确认"  width="40px" height="30px" fontSize="16px" style="margin-left:19px" @btnClick="comfirm2()"  />  
            <le-button v-else txt="修改"  width="40px" height="30px" fontSize="16px" style="margin-left:19px"  @btnClick="isFixed2=true" />  

            </div>
        </div>
        <div class="list-right">
          <div class="label">
            <div class="txt">QQ号</div>
            <div class="hao">：</div>
          </div>
          <div class="value">
            <a-input v-if="isFixed3" v-model="info.account" placeholder="" style="width:180px" :maxLength="20" />
            <span v-else>{{info.account | kong}}</span>
            <le-button v-if="isFixed3" txt="确认"  width="40px" height="30px" fontSize="16px" style="margin-left:19px" @btnClick="comfirm3()"  />  
            <le-button v-else txt="修改"  width="40px" height="30px" fontSize="16px" style="margin-left:19px"  @btnClick="isFixed3=true" />  

          </div>
        </div>
      </div>

      <div class="list">
        <div class="list-left">
          <div class="label">
            <div class="txt">密码</div>
            <div class="hao">：</div>
          </div>
          <div class="value l-height">
            <a-input-password v-if="isFixed4" v-model="info.pw" placeholder="" style="width:180px" :maxLength="20" />
            <span v-else>
            <span v-if="isCha">{{info.pw }}</span>
            <span v-else>{{info.pw |showPw}}</span>
            </span>

            <span v-if="!isFixed4">
            <le-button v-if="isCha" txt="关闭"  width="40px" height="30px" fontSize="16px" style="margin-left:19px" @btnClick="isCha=false"   />
            <le-button v-else txt="查看"  width="40px" height="30px" fontSize="16px" style="margin-left:19px" @btnClick="isCha=true"   />
            </span>

            <le-button v-if="isFixed4" txt="确认"  width="40px" height="30px" fontSize="16px" style="margin-left:19px" @btnClick="comfirm4()"  />  
            <le-button v-else txt="修改"  width="40px" height="30px" fontSize="16px" style="margin-left:19px"  @btnClick="isFixed4=true" />  

          </div>
        </div>
        <div class="list-right">
          <div class="label">
            <div class="txt">彗星号</div>
            <div class="hao">：</div>
          </div>
          <div class="value">
            <div  >{{join}}/{{has}}人已开通</div>
            <le-button  txt="开通"  width="40px" height="30px" fontSize="16px" style="margin-left:19px" @btnClick="sync_hxh()" /> 
            <le-button  txt="关闭" background="linear-gradient(-10deg, #FD8A8F, #FA6A9D)"  width="40px" height="30px" fontSize="16px" style="margin-left:19px" @btnClick="clos_hxh()" /> 
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>

import moment from 'moment'
export default {
  data() {
    return {
      confirmLoading: false,
      checked:false,
      info:{
      },
      isFixed1:false,
      isFixed2:false,
      isFixed3:false,
      isFixed4:false,
      isFixed5:false,
      isCha:false,
      isKai:false, //是否开通了彗星号
      has: 0,
      join: 0,
      // list:[{
      //   key:0,
      //   name:'工会'
      // },{
      //   key:1,
      //   name:'经纪人'
      // }],
      list:[]
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    row: {
      type: Object,
      default: ()=>{
        return {}
      },
    },
  },
  created(){
    this.getRoles()
  },
  computed: {},
  mounted() {
    this.info=this.row
    console.log('info',this.info);
    // this.groupList()
    this.get_hxh()
  },
  filters:{
    showPw(val){
      if(!val){
        return '--'
      }
      let len =val.length
      if(len==0){
        return '--'
      }
      let str=''
      for(let i=0;i<len;i++){
        str=str+'*'
      }
      return str
    },
    setJoin(val){
      if(val){
        return val.slice(0,10)
      }else{
        return '--'
      }
    }
  },
  methods: {
    moment,
    //获取角色列表
    getRoles(){
      this.$api.get(`v2/adminRole/sel?perPage=30`).then((res) => {
        console.log(res);
        if(res.code==200){
          this.list=res.data.list
        }else{
          this.$message.error(res.message)
        }
      });
    },
    get_hxh(){
      this.$api.get(`v1/user/get_hxh?uid=${this.info.uid}`).then((res) => {
        console.log(res);
        if(res.code==200){
          this.has=res.data.has
          this.join=res.data.join
        }else{
          this.$message.error(res.message)
        }
      });
    },
    clos_hxh(){
      this.$api.post(`v1/user/delete_hxh`,{uid:this.info.uid}).then((res) => {
        console.log(res);
        if(res.code==200){
          this.$message.success(res.message)
          this.get_hxh()
        }else{
          this.$message.error(res.message)
        }
      });
    },
    sync_hxh(){
      this.$api.post(`v1/user/sync_hxh`,{uid:this.info.uid}).then((res) => {
        console.log(res);
        if(res.code==200){
          this.$message.success(res.message)
          this.get_hxh()
        }else{
          this.$message.error(res.message)
        }
      });
    },
    comfirm1(){
      let param={
        uid:this.info.uid,
        group:this.info.group
      }
      this.isFixed1=false
      this.toEdit(param,'修改工会成功')
    },
    comfirm2(){
      let param={
        uid:this.info.uid,
        join_time:this.info.join_time
      }
      this.isFixed2=false
      this.toEdit(param,'修改入驻时间成功')
    },
    comfirm3(){
      let param={
        uid:this.info.uid,
        account:this.info.account
      }
      this.isFixed3=false
      this.toEdit(param,'修改QQ号成功')
    },
    comfirm4(){
      let param={
        uid:this.info.uid,
        password:this.info.pw,
      }
      this.isFixed4=false
      this.toEdit(param,'修改密码成功')
    },
    comfirm5(){
      let param={
        uid:this.info.uid,
        role_id:this.info.role_id,
      }

      for(let i=0;i<this.list.length;i++){
        if(this.info.role_id==this.list[i].id){
          this.info.role_name=this.list[i].title
        }
      }

      this.isFixed5=false
      this.toEdit(param,'修改身份成功')
    },

    toEdit(param,msg){
      this.$api.post(`v1/user/edit`,param).then((res) => {
        console.log(res);
        if(res.code==200){
          this.list=res.data.list
          this.$message.success(msg)
        }else{
          this.$message.error(res.message)
        }
      });
    },
    groupList(){
      this.$api.get(`v1/other/group/list`).then((res) => {
        console.log(res);
        if(res.code==200){
          this.list=res.data.list
        }else{
          this.$message.error(res.message)
        }
      });
    },
    handleChange(value){
      console.log(value)
      let select=null
      for(let i =0;i<this.list.length;i++){
        if(this.list[i].id==value){
          select=this.list[i]
        }
      }
      this.info.groups=select
      console.log(this.info)
    },
    changeIdentity(value){
      console.log(value)
      this.info.role_id=value
    },
    handleOk(e) {
      this.$emit("handleOk");
    },
    handleCancel(e) {
      this.$emit("handleCancel");
    },
    onChange(date, dateString) {
      this.info.join_time=dateString
      console.log(this.info);
    },
    onChangeSwitch(){
      console.log(this.checked)
    }

  },
};
</script>

<style lang="less" scoped>
.dashboard-pop-edit-container {
}

.list {
  display: flex;
  margin-bottom: 30px;
  .list-left {
    display: flex;
    width: 350px;
    align-items: center;
  }
  .list-right {
    display: flex;
    align-items: center;
    margin-left: 50px;
  }
}

.label {
  width: 100px;
  font-size: 20px;
  color: #333333;
  display: flex;
  justify-content: space-between;
}
.value {
  font-size: 20px;
  color: #199aec;
  display: flex;
}
.l-height{
  line-height: 30px;
}
@media screen and(max-width:750px) {
  
.list {
  display: block;
  margin-bottom: 0px;
  .list-left {
    display: flex;
    width: 350px;
    align-items: center;
    margin-bottom: 15px;
  }
  .list-right {
    display: flex;
    align-items: center;
    margin-left: 0px;
    margin-bottom: 15px;
  }
}

.label {
  width: 100px;
  font-size: 16px;
  color: #333333;
  display: flex;
  justify-content: space-between;
}
.value {
  font-size: 16px;
  color: #199aec;
  display: flex;
}
.l-height{
  line-height: 30px;
}
}

</style>
